<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>
    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <dark-Toggler class="d-none d-lg-block" />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <NotificationDropdown />
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ user.name }}
            </p>
            <span class="user-status">
              Właściciel biznesu
            </span>
          </div>

          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="business.image !== null ? business.image : require('@/assets/images/avatars/main.png')"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          :to="{ name: 'profile' }"
        >
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>Profil</span>
        </b-dropdown-item>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          :to="{path: '/Wiadomosci'}"
        >
          <feather-icon
            size="16"
            icon="MessageSquareIcon"
            class="mr-50"
          />
          <span>Czat</span>
        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="logout()"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Wyloguj</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import axios from 'axios'
import NotificationDropdown from './NotificationDropdown.vue'

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    NotificationDropdown,
    DarkToggler,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      user: {
        name: '',
      },
      business: {},
    }
  },
  mounted() {
    // set request headers for axios (access token)
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    }

    axios.get(`${process.env.VUE_APP_API_URL}user`, config)
      .then(response => {
        this.business = response.data.business
        this.user = response.data.success
        if (response.data.employee !== null) {
          this.$router.push('/Widok-pracownika')
        } else if (response.data.business !== null) {

        } else {
          swal.fire({
            title: 'Błąd',
            text: 'Nie jesteś właścicielem żadnej firmy',
            icon: 'error',
            confirmButtonText: 'Zamknij',
          })
          this.$router.push('/login')
        }
      })
      .catch(error => {
        console.log(error)

        const config = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        }
        axios.get(`${process.env.VUE_APP_API_URL}logout`, config)
          .then(response => {
            localStorage.removeItem('accessToken')
            this.$router.push('/login')
          })
          .catch(error => {
            localStorage.removeItem('accessToken')
            this.$router.push('/login')
          })
      })
  },
  methods: {
    logout() {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }

      axios.get(`${process.env.VUE_APP_API_URL}Logout`, config)
        .then(response => {
          localStorage.removeItem('accessToken')
          this.$router.push('/login')
        })
        .catch(error => {
          console.log(error)
        })
    },
  },
}
</script>
