<template>
  <b-nav-item-dropdown
    v-if="notifications !== null && notifications.length > 0"
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        :badge="notifications.length"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          Powiadomienia
        </h4>
        <b-badge
          pill
          variant="light-primary"
        >
          {{ notifications.length }} nowych
        </b-badge>
      </div>
    </li>

    <vue-perfect-scrollbar
      v-once
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <!-- Account Notification -->
      <b-link
        v-for="notification in notifications"
        :key="notification.subtitle"
      >
        <router-link
          v-if="notification.type === 'order'"
          :to="{ name: 'Order-show', params: { id: notification.id } }"
        >
          <b-media>
            <p class="media-heading">
              <span class="font-weight-bolder">
                Nowe zamówienie
              </span>
            </p>
            <small class="notification-text">Masz nowe zamówienie od: {{ notification.user.name }} na dzień: {{ notification.date }}</small>
          </b-media>
        </router-link>

        <router-link
          v-if="notification.type === 'reserve'"
          to="/Lista-rezerwowa"
        >
          <b-media>
            <p class="media-heading">
              <span class="font-weight-bolder">
                Nowy wpis do listy rezerwowych
              </span>
            </p>
            <small class="notification-text">Masz nowy wpis do listy rezerwowych od: {{ notification.user.name }} na dzień: {{ notification.date }}</small>
          </b-media>
        </router-link>

      </b-link>

    </vue-perfect-scrollbar>

    <!-- Cart Footer -->
    <li class="dropdown-menu-footer"><b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      block
      @click="MarkAllAsRead"
    >Zaznacz powiadomia jako przeczytane</b-button>
    </li>
  </b-nav-item-dropdown>
</template>

<script>
import axios from 'axios'
import {
  BNavItemDropdown, BBadge, BMedia, BLink, BButton,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import swal from 'sweetalert2'

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    VuePerfectScrollbar,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      notifications: {
        length: 0,
      },
    }
  },
  mounted() {
    this.fetchNotifications()
  },
  methods: {
    MarkAllAsRead() {
      const config = {
        headers: {
          authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }

      axios.post(`${process.env.VUE_APP_API_URL}my-orders/business/mark-as-viewed`, {}, config)
        .then(() => {
          this.notifications = []
          swal.fire('Sukces', 'Wszystkie powiadomienia zostały oznaczone jako przeczytane', 'success')
        })
        .catch(error => {
          console.log(error)
        })
    },
    fetchNotifications() {
      const config = {
        headers: {
          authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }

      axios.get(`${process.env.VUE_APP_API_URL}my-orders/business`, config)
        .then(response => {
          this.notifications = response.data.orders
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.log(error)
        })
    },
  },
}
</script>

<style>

</style>
