export default [
  {
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'Pracownicy',
    route: 'Eployees',
    icon: 'UserIcon',
    children: [
      {
        title: 'Dodaj pracownika',
        route: 'Employee-add',
      },
      {
        title: 'Przeglądaj pracowników',
        route: 'Eployees',
      },
    ],
  },
  {
    title: 'Podsumowania',
    icon: 'ArchiveIcon',
    children: [
      {
        title: 'Podsumowanie',
        route: 'DailySummarySelectEmployee',
      },
    ],
  },
  {
    title: 'Grafik',
    icon: 'ClipboardIcon',
    children: [
      {
        title: 'Grafik',
        route: 'Schedule',
      },
    ],
  },
  {
    title: 'usługi',
    icon: 'InboxIcon',
    children: [
      {
        title: 'Usługi',
        route: 'Offers',
      },
      {
        title: 'Dodaj usługę',
        route: 'Offer-add',
      },
    ],
  },
  {
    title: 'Ustawienia biznesu',
    icon: 'SettingsIcon',
    route: 'BusinessSettings',
  },
  {
    title: 'Klienci',
    icon: 'UserIcon',
    children: [
      {
        title: 'lista',
        route: 'Clients',
      },
      {
        title: 'Dodaj',
        route: 'create-client',
      },
    ],
  },
  {
    title: 'Wiadomości',
    icon: 'MailIcon',
    route: 'Messages',
  },
  {
    title: 'magazyn',
    icon: 'BoxIcon',
    children: [
      {
        title: 'Magazyn',
        route: 'Warehouse',
      },
      {
        title: 'Dodaj produkt',
        route: 'Warehouse-add',
      },
    ],
  },
  {
    title: 'promocje',
    icon: 'DollarSignIcon',
    children: [
      {
        title: 'Promocje',
        route: 'Promotions',
      },
      {
        title: 'Przecena',
        route: 'Promotions-add',
      },
      {
        title: 'Happy hours',
        route: 'HappyHoursAdd',
      },
    ],
  },
  {
    title: 'Sprzęt',
    icon: 'BoxIcon',
    children: [
      {
        title: 'Sprzęt',
        route: 'Equipment',
      },
      {
        title: 'Dodaj sprzęt',
        route: 'Equipment-add',
      },
    ],
  },
  {
    title: 'Profil',
    route: 'profile',
    icon: 'UserIcon',
  },
  {
    title: 'Lista rezerwowa',
    route: 'ReserveList',
    icon: 'FileIcon',
  },
  {
    title: 'Faktury',
    route: 'Invoices',
    icon: 'PaperclipIcon',
  },
  {
    title: 'Importuj dane z booksy',
    route: 'Import-cvs',
    icon: 'FileIcon',
  },
]
